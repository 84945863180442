import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const EyewearCollectionTemplate = ({ data: { eyewearCollection, eyewearCollectionBrands } }) => {

  return (
    <Layout>
      <Seo title={'Eyewear - ' + eyewearCollection.name} description={eyewearCollection.description} />
      <section className="page-header">
        <h1>{eyewearCollection.name}</h1>
      </section>
      <section className="container grid grid-cols-[repeat(auto-fill,minmax(16rem,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(20rem,1fr))] gap-4 auto-rows-fr mt-20 mb-32">
        {eyewearCollectionBrands.nodes.map(brand => (
          <div className="grid group z-10" key={brand.id}>
            <div className="col-span-full row-span-full z-10 overflow-hidden transition duration-300 ease-in-out group-hover:bg-black group-hover:bg-opacity-20">
              <Link to={brand.uri} title={brand.title}>
                <GatsbyImage 
                  image={getImage(brand.brandFields.brandPrimaryImage.localFile)}
                  alt={brand.title} 
                  className="w-full h-full transform transition duration-300 ease-in-out group-hover:scale-110 group-hover:duration-300 mix-blend-overlay"
                />
              </Link>
            </div>
            <Link to={brand.uri}  title={brand.title} className="button col-span-full row-span-full self-end bg-dl-orange bg-opacity-75 text-white mx-4 mb-4 z-10 group-hover:bg-opacity-100 group-hover:duration-300">
              {brand.title}
            </Link>
          </div>
        ))}
      </section>
  </Layout>
  )
}

export default EyewearCollectionTemplate

export const pageQuery = graphql`
  query EyewearCollectionById(
    $id: String!
  ) {
    eyewearCollectionBrands: allWpBrand(
      filter: {brandFields: {eyewearCollection: {id: {eq: $id}}}}
      sort: {order: ASC, fields: title}
    ) {
      nodes {
        id
        title
        uri
        brandFields {
          brandDescription
          brandPrimaryImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    eyewearCollection: wpEyewearCollection(id: {eq: $id}) {
      id
      name
      description
    }

    # eyewearCollection: wpEyewearCollection(id: {eq: $id}) {
    #   id
    #   name
    #   description
    #   brands {
    #     nodes {
    #       id
    #       title
    #       uri
    #       brandFields {
    #         brandDescription
    #         brandPrimaryImage {
    #           localFile {
    #             childImageSharp {
    #               gatsbyImageData(
    #                 quality: 100
    #                 width: 500
    #                 placeholder: BLURRED
    #                 # layout: FULL_WIDTH
    #                 formats: [AUTO, WEBP, AVIF]
    #               )
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
